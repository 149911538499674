<template>
	<el-container>
		<el-header>{{$config.APP_NAME}}</el-header>
		<el-main style="display: flex; align-items: center; justify-content: center;">
			<el-result icon="warning" title="非法闯入后台" subTitle="请不要多次尝试，否则系统自动锁定账户 OR 联系系统管理员Email：13243877782@163.com">
				<template slot="icon">
					<img style="width: 400px" src="@/assets/noaccess.svg" alt="">
				</template>
				<template slot="extra">
					<el-button type="primary" size="medium" @click="$utils.toRoute('/login')">{{time}}s 后跳转</el-button>
				</template>
			</el-result>
		</el-main>
		<el-footer>CopyRight 2019 - {{year}} {{$config.COMPANY}} 版权所有</el-footer>
	</el-container>
</template>

<style>
	.el-header,
	.el-footer {
		color: #fff;
		font-size: 20px;
		line-height: 60px;
		background-color: #2090c5
	}
	.el-footer {
		font-size: 14px;
		text-align: center;
	}
	.el-main {
		height: calc(100vh - 120px);
	}
</style>

<script>
	import { mapActions } from 'vuex'
	export default {
		computed: {
			year () {
				var d = new Date();
				return d.getFullYear();
			}
		},
		data () {
			return {
				time: 10
			}
		},
		methods: {
			...mapActions(['logout'])
		},
		created () {
			this.logout();
			setInterval(() => {
				if (this.time === 1) {
					this.$utils.toRoute('/login');
				}
				this.time--
			}, 1000);
		}
	}
</script>
